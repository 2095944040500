import React from "react"
import Layout from "../components/Layout"
import "../css/about.css"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import { Link } from "gatsby"
import Particle5 from "../components/Particle5"
import SEO from "../components/SEO"

const about = () => {
  return (
    <Layout>
      <SEO title="About" description="This is about page of Arpit Somani" />
      <Particle5></Particle5>
      <div className="indian">
        <p>
          <mark className="in">IN</mark>
          <mark className="di">DI</mark>
          <mark className="an">AN</mark>
        </p>
      </div>
      <h6>s</h6>
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>

      <div className="ab-page">
        <h1>About</h1>
      </div>
      <div id="quotes"></div>
      <div className="wwe">
        <h4>"The Quote Box"</h4>
      </div>
      <div className="act-h">
        <div className="quote-box noSelect">
          <div id="quote-box" className="quotes">
            <p class="quote">Well, It's all about a impeccable life.</p>
          </div>

          <p class="source ">
            <span class="auto">~Arpit Somani</span>
          </p>
        </div>
      </div>

      <section className="abot-page noSelect">
        <p>
          Hello,
          <br />
          <br />
          Mainly, in about page, it's very important to mention what I do, as
          well as what I like to do... that's what it's all about.
          <br />
          <br />
          <h3>So let's start from the professional view:-</h3>
          <br />
          <br />
          As, currently I am a Engeneering student persuing B.tech degree in
          Computer Science Field.
          <br />
          So, as I am connected to CS/IT area, lets simply start from coding.
          <br />
          <br />
          I like coding. Mostly, I prefer python as my default coding language.
          <br />
          Well, I am a self-taught programmer.
          <br />I did quite of programming projects with python, you can
          checkout my projects{" "}
          <Link className="gop" to="/pythonprojectcard/">
            Here
          </Link>{" "}
          ...
          <br />
          <br />
          I solve Coding Questions regularly on various well-known online
          platforms.
          <br />
          (Like:- Hackerrank, Hackerearth, etc.)
          <br />
          <br />
          I also did explore the web-dev area. <br />
          (why i choose to explore it:- the reason is in front of you)
          <br />
          <br />
          Checkout it's cool projects{" "}
          <Link className="gop" to="/webprojectcard/">
            Here
          </Link>{" "}
          ...
          <br />
          <br />
          I Love to explore new tech fields.
          <br />
          Currently I am diving in ML/DL/AL , one of my all time favourite
          fields.
          <br />
          <br />
          Checkout it's wonderful projects{" "}
          <Link className="gop" to="/ml/">
            Here
          </Link>{" "}
          ...
          <br />
          <br />
          I beleive in :- Learn it, Earn it.
          <br />
          <br />
          <Link className="goop"  to="/special_proj/">
           "About My Website"
          </Link>
          <br />
          <br />
          Well, I also write a blog.
          <br />
          I share what I know as well as what I am learning.
          <br />
          Look for them{" "}
          <Link className="gop" to="/blog/">
            Here
          </Link>{" "}
          ...
          <br />
          <br />
          <h3> Now, in some Personal View:-</h3>
          <br />
          <br />
          What kind of man am I ?<br />
          Let me tell you simply : Almost everyone like momos but for me Extra
          Red Chutney is more important with it. <br />
          (So, yes, you can say I am a foodie.😂)
          <br />
          <br />
          I write quotes/thoughts, as you can see my beautiful "Quote Box"
          up-above.
          <br />
          <br />
          I also write songs.
          <br />
          I have already written few songs.
          <br />
          For now I am looking into some music to play with them.
          <br />
          (but, the truth is I don't want to share them as a poem 😂)
          <br />
          Some music is must... <br />
          Well let's see I will figure out something. <br />
          <br />
          Still I don't know about showing it. <br />
          As if I find some music for them... Still
          <br />
          First I will sing and record it.
          <br />
          Then I will listen
          <br />
          Then I will decide what to do with it... 😂
          <br />
          <br />
          I am also writing a story,
          <br />
          Obviously a Sci-fi one,
          <br />
          Who doesn't want to expand and break the boundries of imagination.
          <br />
          In short I write everything I like, well I am always a writer
          somewhere.
          <br />
          Well, I just started it, so it's not coming very soon, I have lots of
          plot in my mind, let's see how it goes.
          <br />
          <br />
          Until then, let's keep writing...
          <br />
          <br />
          I also like to play guitar as well as sing along. <br />
          I am a beginner here... <br />
          But yes, I can play few songs. <br />
          I can't say, I sing good, but yes, you will not get irritated by my
          voice, this is for sure😂. <br />
          <br />
          I will definitely upload some clips very soon.
          <br />
          <br />
        </p>
      </section>

      <div className="guitar-heading">
        <h3>Music Please...</h3>
      </div>
      <Link>
        <div className="button-7">
          <div class="eff-7"></div>
          <h2>
            Guitar<h5 className="por">(Coming soon !)</h5>
          </h2>
        </div>
      </Link>
    </Layout>
  )
}
export default about
